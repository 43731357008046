/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState } from "react";
import { useGLTF, Html } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export default function MacbookNew({ scale }) {
  const group = useRef();

  const { nodes, materials } = useGLTF("models/macbook.gltf");
  return (
    <group ref={group} scale={scale} position-y={-1.2} dispose={null}>
      <group position={[0, 0.52, 0]} scale={[0.1, 0.1, 0.1]}>
        <mesh
          geometry={nodes.Circle001.geometry}
          // material={nodes.Circle001.material}
        >
          <meshStandardMaterial color="#A4B6D0" />
        </mesh>
        <mesh
          geometry={nodes.Circle001_1.geometry}
          material={nodes.Circle001_1.material}
        />
        <mesh
          geometry={nodes.Circle001_2.geometry}
          material={materials.HeadPhoneHole}
        />
        <mesh
          geometry={nodes.Circle001_3.geometry}
          material={nodes.Circle001_3.material}
        />
        {/* touchpad */}
        <mesh
          geometry={nodes.Circle001_4.geometry}
          // material={nodes.Circle001_4.material}
        >
          <meshStandardMaterial color="#AAC2E5" />
        </mesh>
        <mesh
          geometry={nodes.Circle001_5.geometry}
          material={materials.TouchbarBorder}
        />
        <mesh
          geometry={nodes.Circle001_6.geometry}
          material={materials.Keyboard}
        />
        {/* camera */}
        <mesh
          geometry={nodes.FrontCameraRing001.geometry}
          // material={materials["CameraRIngBlack.002"]}
          position={[-0.15, 19.57, -16.15]}
          scale={5.8}
        >
          <meshStandardMaterial color="navy" />
        </mesh>
        <mesh
          geometry={nodes.KeyboardKeyHole.geometry}
          // material={nodes.KeyboardKeyHole.material}
          position={[-11.79, -0.15, -8.3]}
          scale={5.8}
        >
          <meshStandardMaterial color="#AAC2E5" />
        </mesh>
        <mesh
          geometry={nodes.RubberFoot.geometry}
          material={materials.DarkRubber}
          position={[-11.95, -0.75, 7.86]}
          scale={5.8}
        />
        <group position={[0.01, -0.21, -10.56]} scale={5.8}>
          <mesh
            geometry={nodes.Circle012.geometry}
            material={materials.HingeBlack}
          />
          <mesh
            geometry={nodes.Circle012_1.geometry}
            material={materials.HingeMetal}
          />
        </group>
        <group position={[0, -0.51, 0]} scale={5.8}>
          <mesh
            geometry={nodes.Circle006.geometry}
            material={nodes.Circle006.material}
          />
          <mesh
            geometry={nodes.Circle006_1.geometry}
            material={nodes.Circle006_1.material}
          />
        </group>
        <group position={[-11.79, -0.15, -8.3]} scale={5.8}>
          <mesh
            geometry={nodes.Circle.geometry}
            material={nodes.Circle.material}
          />
          <mesh geometry={nodes.Circle_1.geometry} material={materials.Key} />
          <mesh
            geometry={nodes.Circle_2.geometry}
            // material={materials.Touchbar}
          >
            <meshStandardMaterial color="#3D3D3D" />
          </mesh>
        </group>
        <group
          position={[0.01, -0.47, -10.41]}
          rotation={[1.31, 0, 0]}
          scale={5.8}
        >
          <mesh
            geometry={nodes.Circle002.geometry}
            material={nodes.Circle002.material}
          >
            <meshStandardMaterial color="#A4B6D0" />
          </mesh>
          <mesh
            geometry={nodes.Circle002_1.geometry}
            material={materials.Screen}
          />
          {/* screen border */}
          <mesh
            geometry={nodes.Circle002_2.geometry}
            // material={materials.ScreenGlass}
          >
            <meshStandardMaterial color="#001242" />
          </mesh>
          <mesh
            geometry={nodes.Circle002_3.geometry}
            material={materials.Rubber}
          />
          <mesh
            geometry={nodes.Circle002_4.geometry}
            material={materials.DisplayGlass}
          />
          {/* logo */}
          <mesh
            geometry={nodes.AppleLogo000.geometry}
            // material={materials["AppleLogo.004"]}
            position={[0, -0.12, -1.8]}
            rotation={[0, 0, 0]}
            scale={[0.58, 0.58, 0.58]}
          ></mesh>
        </group>
        <group position={[12.2, 0.03, 0.6]} scale={5.8}>
          <mesh
            geometry={nodes.Circle003.geometry}
            material={nodes.Circle003.material}
          />
          <mesh
            geometry={nodes.Circle003_1.geometry}
            material={nodes.Circle003_1.material}
          />
        </group>
        <group position={[-15.03, 0.03, 0.6]} scale={5.8}>
          <mesh
            geometry={nodes.Circle009.geometry}
            material={nodes.Circle009.material}
          />
          <mesh
            geometry={nodes.Circle009_1.geometry}
            material={nodes.Circle009_1.material}
          />
        </group>
      </group>
      <Html
        transform
        wrapperClass="macbook-screen"
        distanceFactor={1.17}
        position={[0, 1.49, -1.355]}
        rotation-x={-0.256}
        scale={0.98}
      >
        {/* <iframe src="http://3d-portfolio-iframe.s3-website-us-west-1.amazonaws.com/" /> */}
        <iframe src="https://portfolio-html-dusky.vercel.app/" />
      </Html>
    </group>
  );
}

useGLTF.preload(
  "https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/macbook/model.gltf"
);
